import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from 'react';
import axios from 'axios';
import { useStore } from '../store/Store';
const CookieTokenName = 'xWilBask';
const MaxQuantity = 12;

const basketContext = createContext();
basketContext.displayName = 'Auth';

export function ProvideBasket({ children }) {
  const auth = useProvideBasket();
  return (
    <basketContext.Provider value={auth}>{children}</basketContext.Provider>
  );
}

export const useBasket = () => {
  return useContext(basketContext);
};

function useProvideBasket() {
  const store = useStore();
  const listeners = useRef({
    onAdded: [],
  });
  let local = null;
  if (typeof localStorage !== 'undefined') {
    local = localStorage.getItem(CookieTokenName);
  }
  const [basket, setBasket] = useState(
    local ? JSON.parse(local) : { lines: [], coupon: null }
  );
  const [shipping, setShipping] = useState({
    countryId: 68,
    price: null,
    isLoading: true,
  });

  useEffect(() => {
    if (store.get('store').maintenance === true) {
      setShipping({
        countryId: 68,
        price: 18,
        isLoading: false,
      });
    } else {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(CookieTokenName, JSON.stringify(basket));
      }
      setShipping((prevShipping) => ({ ...prevShipping, isLoading: true }));

      axios
        .post(`/_api/checkout/shipping-price?t=${Date.now()}`, {
          countryId: shipping.countryId,
          lines: basket.lines,
        })
        .then(({ data }) => {
          setShipping((prevShipping) => ({
            ...prevShipping,
            price: data.shippingPrice,
            isLoading: false,
          }));
        });
    }
  }, [basket]);

  useEffect(() => {
    if (store.get('store').maintenance === true) {
      setShipping({
        countryId: 68,
        price: 18,
        isLoading: false,
      });
    } else {
      setShipping((prevShipping) => ({ ...prevShipping, isLoading: true }));
      axios
        .post(`/_api/checkout/shipping-price?t=${Date.now()}`, {
          countryId: shipping.countryId,
          lines: basket.lines,
        })
        .then(({ data }) => {
          setShipping((prevShipping) => ({
            ...prevShipping,
            price: data.shippingPrice,
            isLoading: false,
          }));
        });
    }
  }, [shipping.countryId]);

  const add = (campaign, product, color, size, quantity, maintenance) => {
    setBasket((prevBasket) => {
      const lines = prevBasket.lines;
      const productIndex = lines.findIndex(
        (p) =>
          p.product.id === product.id &&
          p.color.id === color.id &&
          p.size.id === size.id
      );
      if (productIndex === -1) {
        lines.push({
          product: {
            id: product.id,
            name: campaign.name,
            image: color.images[0],
            price: product.price, // @todo : check if price has changed, notify the client when needed
          }, // don't keep every informations ()
          color,
          size,
          quantity:
            quantity > MaxQuantity && maintenance === false
              ? MaxQuantity
              : quantity,
        });
      } else {
        lines[productIndex].quantity += quantity;
        lines[productIndex].quantity =
          lines[productIndex].quantity > MaxQuantity
            ? MaxQuantity
            : lines[productIndex].quantity;
      }

      return Object.assign({}, prevBasket, { lines });
    });
    listeners.current.onAdded.map((cb) => cb());
  };

  const remove = (productId) => {
    setBasket((prevBasket) => {
      const lines = prevBasket.lines;
      const productIndex = lines.findIndex((p) => p.product.id === productId);
      if (productIndex !== -1) {
        lines.splice(productIndex, 1);
      }

      return Object.assign({}, prevBasket, { lines });
    });
  };

  const addCouponCode = (code) => {
    return axios
      .get(`/_api/checkout/check-coupon/${code}?t=${Date.now()}`)
      .then(({ data }) => {
        const coupon = data;

        let amount = 0;
        if (coupon.apply === 1) {
          // One product
          const mostExpensiveLine = basket.lines.reduce((most, line) => {
            if (most === null || line.product.price > most.product.price) {
              return line;
            }
          }, null);

          if (coupon.type === 1) {
            // Percent of price
            amount = (mostExpensiveLine.product.price * coupon.value) / 100;
          } else {
            // fixed value
            amount = coupon.value;
          }
          if (amount > coupon.value) {
            amount = coupon.value;
          }
        } else {
          // all products
          amount = basket.lines.reduce((amnt, line) => {
            let reduc = 0;
            if (coupon.type === 1) {
              // Percent of price
              reduc = (line.product.price * coupon.value) / 100;
            } else {
              // fixed value
              reduc = coupon.value;
            }
            if (reduc > coupon.value) {
              reduc = coupon.value;
            }

            return reduc + amnt;
          }, amount);
        }
        setBasket((prevBasket) => {
          return Object.assign({}, prevBasket, {
            coupon: Object.assign({}, coupon, { amount }),
          });
        });
      });
  };

  const removeCoupon = (code) => {
    setBasket((prevBasket) => {
      return Object.assign({}, prevBasket, { coupon: null });
    });
  };

  const getTotalTTC = (withShipping = true, withCoupon = true) => {
    const totalTTC = basket.lines.reduce((totalTTC, line) => {
      return totalTTC + line.quantity * line.product.price;
    }, (withShipping ? getShippingPrice() : 0) - (withCoupon ? (basket.coupon ? basket.coupon.amount : 0) : 0));
    return Math.round(totalTTC * 100, 10) / 100;
  };

  const getShippingPrice = () => {
    return shipping.price;
  };

  const reset = () => {
    setBasket({ lines: [], coupon: null });
  };

  return {
    add,
    remove,
    reset,
    basket,
    shipping,
    getTotalTTC,
    getShippingPrice,
    addCouponCode,
    removeCoupon,
    setShippingCountryId: (countryId) => {
      setShipping((prevShipping) => ({ ...prevShipping, countryId }));
    },
    addListener: (event, cb) => {
      listeners.current[event].push(cb);
    },
    removeListener: (event, cb) => {
      listeners.current[event] = listeners.current[event].filter(
        (listener) => listener === cb
      );
    },
  };
}
