/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { useRouter } from '../hooks/router/Router';
import axios from 'axios';
import './code.scss';
import { useStore } from '../hooks/store/Store';

export default function Code({ isValid, setIsValid }) {
  const store = useStore();
  const [code, setCode] = useState(''); // État pour le code saisi
  const [cover, setCover] = useState(
    'https://wiltee.com/assets/home/gifhome-montage.gif'
  ); // État pour le code saisi
  const [error, setError] = useState(''); // État pour l'affichage de l'erreur

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // Si l'utilisateur a déjà validé le code, le rediriger vers la boutique
    if (localStorage.getItem('accessCode') === 'valid') {
      setIsValid(true);
    }
    checkFile();
  }, [isValid]);

  const checkFile = async () => {
    try {
      const response = await fetch(store.get('store').maintenanceCover, {
        method: 'HEAD',
      });
      if (response.ok && store.get('store').maintenanceCover) {
        setCover(store.get('store').maintenanceCover); // Fichier trouvé
      }
    } catch (error) {
      console.error('Erreur lors de la vérification du fichier :', error);
    }
  };

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!code) {
      setError('Code incorrect. Veuillez saisir un code.');
      return;
    }
    const { data } = await axios.get(`/_api/check-code/${code}`);

    if (data?.code === true) {
      // Si le code est correct, stocker dans localStorage et rediriger vers la boutique
      localStorage.setItem('accessCode', 'valid');

      setIsValid(true);
    } else {
      // Afficher un message d'erreur si le code est incorrect
      setError('Code incorrect. Veuillez réessayer.');
    }
  };

  return (
    <div style={styles.body}>
      <img src={cover} className={'code-image'} />
      <div style={styles.container} className={'code-code'}>
        <h2>Accès à la boutique</h2>
        <p>Veuillez saisir le code d'accès pour continuer.</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)} // Mettre à jour l'état avec la valeur du champ
            placeholder="Entrez le code"
            style={styles.input}
          />
          <button type="submit" style={styles.button}>
            Valider
          </button>
        </form>
        {error && <p style={styles.error}>{error}</p>} {/* Afficher l'erreur */}
      </div>
    </div>
  );
}

// Styles pour la page
const styles = {
  body: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '300px',
    textAlign: 'center',
    margin: '0 auto',
  },
  input: {
    padding: '10px',
    width: '100%',
    margin: '10px 0',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  button: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '100%',
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
};
