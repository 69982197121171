import React from 'react';

const Copyright = () => {
  return (
    // biome-ignore lint/a11y/noBlankTarget: <explanation>
    <a href="https://drylead.agency" target="_blank" title="Drylead" width={80}>
      <img
        src={'https://drylead.agency/logo/logo.png'}
        width={16}
        height={16}
        alt="Agence Web • Mobile • PWA • SEO © Drylead"
      />
    </a>
  );
};

export default Copyright;
